import React, { useEffect, useState } from 'react';
import dataJson from '../../assets/data/servicesData.json';
import { ButtonContent } from '../global/boton/ButtonContent';


const Busqueda = ({ categoryPorDefecto = '', phone, imageDefault }) => {
    const [cardProduct, setCardProduct] = useState('');
    const [search, setSearch] = useState('');

    // Function for search input
    const searcher = (e) => {
        setSearch(e.target.value);
        setCardProduct('');
    };

    // Function to highlight search terms in the result
    const highlightText = (text, highlight) => {
        if (!highlight.trim()) {
            return text;
        }
        const regex = new RegExp(`(${highlight})`, 'gi');
        const parts = text.split(regex);
        return parts.map((part, index) =>
            regex.test(part) ? (
                <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>
            ) : (
                part
            )
        );
    };

    // Filtering results based on search input or selected category
    const resultFilter = !search && !cardProduct
        ? dataJson.categories
        : dataJson.categories.filter((category) =>
            cardProduct
                ? category.category.toLowerCase() === cardProduct.toLowerCase()
                : category.items.some((item) =>
                    item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                )
        );

    // Handling select dropdown change
    const handleSelectChange = (e) => {
        setCardProduct(e.target.value);
        setSearch('');
    };

    // Set default category if provided
    useEffect(() => {
        if (categoryPorDefecto) {
            setCardProduct(categoryPorDefecto);
            setSearch('');
        }
    }, [categoryPorDefecto]);

    return (
        <div className='h-full w-full  pb-20'>
            {
                !categoryPorDefecto && (
                    <div>
                        <div className="flex flex-col-reverse md:flex-row md:gap-0 gap-5 py-20">
                            <select
                                className={`flex-shrink-0 z-10 py-2.5 px-4 text-sm font-medium text-start text-gray-900 bg-gray-100 border border-gray-300 rounded-lg md:rounded-r-none md:rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 ${!search ? 'block' : 'hidden md:inline-flex'}`}
                                onChange={handleSelectChange}
                            >
                                <option selected value=''>All Products</option>
                                {dataJson.categories?.map((item, index) => (
                                    <option key={index} value={item.category}>{item.category}</option>
                                ))}
                            </select>

                            <div className="relative w-full">
                                <input
                                    type="search"
                                    className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg md:rounded-l-none md:rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Search for products ..."
                                    required
                                    value={search}
                                    onChange={searcher}
                                />
                                <div className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300">
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                        ></path>
                                    </svg>
                                    <span className="sr-only">Search</span>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            }

            <div className='w-full h-full flex justify-center flex-wrap gap-6 items-start'>
                {
                    resultFilter?.map((category) => (
                        category.items.map((item, itemIndex) => (
                            <div key={itemIndex} className="w-full max-w-xs p-4 bg-white border border-gray-200 rounded-lg shadow-md flex flex-col justify-between h-full min-h-[500px]">
                                <img
                                    src={item.image ? `/images/products/${item.image}` : imageDefault}
                                    alt={item.name}
                                    className="w-full h-48 object-contain object-center mb-3 border rounded-lg"

                                />
                                <h3 className="text-lg font-semibold mb-2">{highlightText(item.name, search)}</h3>
                                <p className="text-[15px] leading-5   text-gray-600">Sold By: {item.soldBy}</p>
                                {item.description && <p className="text-[15px] leading-4 text-gray-600">Description: {item.description}</p>}
                                {item.dimensions && <p className="text-[15px] leading-5   text-gray-600">Dimensions: {item.dimensions}</p>}
                                {item.quantityPerPallet && <p className="text-[15px] leading-5   text-gray-600">Quantity per Pallet: {item.quantityPerPallet}</p>}
                                {item.coverage && <p className="text-[15px] leading-5   text-gray-600">Coverage: {item.coverage}</p>}
                                {item.price && <p className="text-[15px] leading-5   text-gray-600">Price: $ {item.price}</p>}
                                {item.availability && <p className="text-[15px] leading-5   text-gray-600">Availability: {item.availability}</p>}
                                <div className='flex justify-center'>
                                    <ButtonContent btnUrl={`https://wa.me/1${phone}`} />
                                </div>
                            </div>
                        ))
                    ))
                }
            </div>

        </div>
    )
}

export default Busqueda;
